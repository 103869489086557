import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../config/URL";

export default function RaceView() {
    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await api.get(`/getAllRaceSettingById/${id}`);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching data ", error);
            }
        };
        getData();
    }, [id]);

    return (
        <div>
            <div className="container">
                <div className="row mt-2 pb-3">
                    <div className="my-3 d-flex justify-content-end mb-5">
                        <Link to={"/race"}>
                            <button type="button" className="btn btn-sm btn-border">
                                Back
                            </button>
                        </Link>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="row  mb-2 ">
                            <div className="col-6  ">
                                <p className="fw-medium">race</p>
                            </div>
                            <div className="col-6">
                                <p className="text-muted text-sm">: {data.race}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
